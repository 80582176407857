.page-journal-promo-bg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    .animation-layer {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .journal-block {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .bottom-block {
        border-radius: 12px;
        padding: 18px 26px;
        display: inline-flex;
        flex-direction: column;
        margin: 30px 20px;
        background: rgba(255, 255, 255, 0.67);
        backdrop-filter: blur(10px);
    }


}

.block-journal-promo-bg {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    .animation-layer {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .promo-newspapers_item {
        display: flex;
        box-shadow: 0 10px 20px #0000006e;
    }

    .promo-newspapers_item,
    .page-journal-promo-text-lines {
        position: relative;
        z-index: 2;
    }
}

.page-journal-promo-text-lines {
    display: inline-flex;
    margin: 30px auto;
    // width: auto;
    align-items: center;
    align-content: stretch;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    font-size: 18px;
    font-weight: 600;

    gap: 1em;
    border-radius: 12px;
    padding: 18px 26px;

    box-shadow: 0px 0px 19px 5px rgba(2, 2, 2, 0.25);

    p {
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .page-journal-promo-text-lines {
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
}