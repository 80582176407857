@import '../../default';

header {
    // position: relative;
    // z-index: 3;
    background: $clr_blue;
    background: linear-gradient(160deg, #0a1a5c 10%, #941b4c 100%);
    background: linear-gradient(160deg, #0a1a5c 10%, #0f247a 100%);
}

.header-desktop {
    padding: 0rem 0 1rem 0;
    font-size: 13px;
    font-weight: 500;
    z-index: 101;
    position: relative;

    @media screen and (max-width : 991px) {
        display: flex;
        align-items: center;
        padding: 0;
        height: 60px;
        margin-left: 25px;

        .container .row {
            align-items: center;
        }
    }

    p {
        margin: 0;
    }

    .header {
        &-logo {
            max-width: inherit;
            flex-grow: unset;
            padding-top: 10px;
            padding-right: 50px;
            animation: .7s both FadeInBlock;

            @media screen and (max-width : 991px) {
                padding: 0 20px 0 0;
            }

            img {
                &.logo-m {
                    display: none;
                    height: 40px;
                    margin-left: 10px;
                }

                @media screen and (max-width : 991px) {
                    display: none;

                    &.logo-m {
                        display: block;
                    }
                }

                @media screen and (max-width : 768px) {
                    &.logo-m {
                        display: none;
                    }
                }
            }
        }

        &-menu {
            flex-grow: 1;

            &__top {
                @media screen and (max-width : 991px) {
                    display: none;
                }
            }

            &__main {
                margin-left: -1rem;

                //margin-top: -1.5rem;
                a {
                    color: $clr_white;

                    &.red {
                        font-weight: 600;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &__contact {
                &>a {
                    color: $clr_white;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .callback-modal {
                    color: $clr_white;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .contact-popup {
                    position: relative;

                    &:hover {
                        .contact-popup__wrap {
                            opacity: 1;
                            z-index: 1;
                            visibility: visible;
                            transform: translateY(0);
                            transform: translateY(-5px);
                            transform: translateY(-15px);
                        }
                    }

                    &__btn {
                        padding: .7rem 1rem 1rem 0;
                        cursor: pointer;

                        &:hover {}
                    }

                    &__wrap {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(5px);
                        position: absolute;
                        top: 100%;
                        right: 0;
                        transition: .3s;
                        padding-top: 10px;
                    }

                    &__inner {
                        padding: 1rem;
                        background: $clr_white;
                        border-radius: 5px;
                        white-space: nowrap;
                        box-shadow: 5px 2px 15px transparentize($clr_blue, .8);

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 3px;
                            right: 18px;
                            width: 0;
                            height: 0;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-bottom: 8px solid white;
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                        }

                        .callback {
                            display: flex;
                            flex-direction: column;

                            &:after {
                                content: "";
                                display: block;
                                width: 40%;
                                margin: auto;
                                height: 1px;
                                background-color: $clr_gray;
                                margin: .5rem auto;
                            }

                            &__form {
                                display: flex;
                                align-items: center;

                                input {
                                    width: 101px;
                                    padding: 2px 5px;
                                    border-radius: 4px;
                                    border: 1px solid $clr_gray;
                                    margin-right: 5px;
                                }

                                button {
                                    font-size: 11px;
                                    padding: 3px 10px;
                                    //width: 50px;
                                    white-space: initial;
                                    line-height: 10px;
                                    border: none;
                                    background: $clr_orange;

                                    &:hover {
                                        background: lighten($clr_orange, 5%);
                                    }
                                }
                            }
                        }

                        .call-chat {
                            font-size: 12px;
                            padding: 4px 10px;
                            //width: 50px;
                            white-space: initial;
                            line-height: inherit;
                            border: none;
                            background: $clr_blueLighten;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            text-align: center;
                            //before modal callback
                            margin-top: 15px;
                            position: relative;

                            &:after {
                                content: "";
                                display: block;
                                width: 40%;
                                margin: auto;
                                height: 1px;
                                background-color: $clr_gray;
                                margin: .5rem auto;
                                position: absolute;
                                top: -15px;
                            }

                            //before modal callback
                            &:hover {
                                background: lighten($clr_blueLighten, 10%);
                            }

                            i {
                                display: block;
                                width: 20px;
                                height: 12px;
                                background: url('../../../img/i-email.svg') center center no-repeat;
                                background-size: contain;
                                margin-right: 5px;
                            }
                        }

                        .worktime {
                            &__wrap {
                                padding: 10px 10px 0;
                                font-size: 14px;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:before {
                                    content: "";
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    background: url('../../../img/i-clock.svg') center center no-repeat;
                                    background-size: contain;
                                    margin-right: 5px;
                                }

                                span {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }

            &__bottom {
                @media screen and (max-width : 991px) {
                    // margin-right: -1rem;
                }

                .catalog-btn {
                    //background: $clr_primary;
                    border: transparent;
                    text-transform: uppercase;
                    font-weight: 500;
                    line-height: initial;
                    font-size: 18px;
                    padding: 10px 20px;

                    @media screen and (max-width : 991px) {
                        display: none;
                    }
                }

                .header-search {
                    flex-grow: 1;

                    .search {
                        margin-left: 1.5rem;
                        background: white;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        padding: 0 2px;

                        label {
                            margin: 0 5px 0 10px;

                            &:before {
                                content: "";
                                display: block;
                                width: 20px;
                                height: 20px;
                                background: url('../../../img/i-search.svg') center center no-repeat;
                                background-size: contain;
                                margin: 0 5px;
                            }
                        }

                        input {
                            padding: 22px 10px;
                            border: none;
                            width: 100%;

                            &:focus,
                            &:active {
                                box-shadow: none;
                            }
                        }

                        button {
                            line-height: initial;
                            padding: 9px 15px;
                            font-weight: 500;

                            &:disabled {
                                opacity: 1;
                                border-color: #bf2362;
                            }
                        }

                        @media screen and (max-width : 991px) {
                            margin-left: 0;

                            label {
                                opacity: 0;
                                position: absolute;
                                visibility: hidden;
                            }

                            button {
                                content: "";
                                display: block;
                                font-size: 0;
                                background: #bf2362 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M13.3563 2.28848C10.3051 -0.7625 5.33907 -0.7625 2.28789 2.28848C-0.762629 5.34011 -0.762629 10.3051 2.28789 13.3568C5.00504 16.0731 9.23688 16.3641 12.2861 14.243C12.3502 14.5466 12.4971 14.8363 12.7332 15.0724L17.1767 19.5156C17.8242 20.1618 18.8706 20.1618 19.5148 19.5156C20.1617 18.8688 20.1617 17.8225 19.5148 17.1776L15.0714 12.7331C14.8365 12.499 14.5462 12.3515 14.2426 12.2873C16.3651 9.23767 16.0741 5.00678 13.3563 2.28848ZM11.9534 11.954C9.6754 14.2318 5.96809 14.2318 3.69078 11.954C1.41414 9.67616 1.41414 5.96975 3.69078 3.69194C5.96809 1.41478 9.6754 1.41478 11.9534 3.69194C14.2313 5.96975 14.2313 9.67616 11.9534 11.954Z' fill='white'/%3E%3C/svg%3E") center center no-repeat;
                                background-size: inherit;
                                margin: 0 2px;
                                border-radius: 4px;
                                border: none;
                                height: 36px;
                                width: 38px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-promo {

    // position: relative;
    // z-index: 102;
    a {
        display: block;
        padding: 0;
        height: 35px;
    }

    img {
        width: 100%;
        height: 35px;
        object-position: center;
        object-fit: cover;
    }

    &.disabled {
        display: none;
    }

    @media screen and (max-width : 991px) {

        //  qdisplay: none;
        a {
            height: 25px;

            img {
                height: 25px;
                vertical-align: baseline;
                position: absolute;
            }
        }
    }
}

.with-top-banner {
    .navbar-toggler {
        // top: 34px;
        // top: -50px;

    }
}

.navbar-toggler {
    display: none;
    padding: 9px 0 0 0;
    position: absolute;
    left: 15px;
    // top: 12px;
    top: -50px;
    z-index: 100;

    @media screen and (max-width : 991px) {
        display: block;
    }

    &:focus {
        outline: none;
    }

    &-icon {
        .navbtn {
            width: 100%;
            cursor: pointer;
        }

        span {
            display: block;
            width: 100%;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
            border-radius: 3px;
            height: 3px;
            background: #fff;
            transition: all .3s;
            position: relative;
        }

        span+span {
            margin-top: 6px;
        }

        .active span:nth-child(1) {
            animation: ease .7s top forwards;
        }

        .not-active span:nth-child(1) {
            animation: ease .7s top-2 forwards;
        }

        .active span:nth-child(2) {
            animation: ease .7s scaled forwards;
        }

        .not-active span:nth-child(2) {
            animation: ease .7s scaled-2 forwards;
        }

        .active span:nth-child(3) {
            animation: ease .7s bottom forwards;
        }

        .not-active span:nth-child(3) {
            animation: ease .7s bottom-2 forwards;
        }
    }
}

.nav-mobile {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: hidden;
    left: 0;
    right: 0;
    z-index: 1000;
    z-index: 1001;
    transition: .4s;

    .user_info {
        display: flex;
        align-items: center;
        padding: 0 20px 10px;
        border-bottom: 1px solid #e9e9e9;

        img {
            width: 50px;
            height: 50px;
            min-width: 50px;
        }

        h5 {
            font-size: 15px;
            margin: 0 0 0 10px;
            width: 100%;
        }
    }

    &__close {
        color: $clr_white;
        font-size: 18px;
        opacity: .7;
        position: absolute;
        top: 5px;
        right: 0px;
    }

    &__overlay {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparentize($clr_blue, .4);
        transition: 0.3s;
    }

    &__wrap {
        background: $clr_white;
        width: 280px;
        position: relative;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4), 1px 0 2px rgba(0, 0, 0, 0.1);
        transform: translateX(-105%);
        transition: .4s;
    }

    &__menu {
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 110px;

        .menu-list {
            text-decoration: none;
            list-style: none;
            padding: 10px 0;
            margin: 0;

            // border-bottom: 1px solid #e9e9e9;
            a {
                font-size: 14px;
                font-weight: 500;
                color: $clr_black;
                padding: .4rem 1.5rem;

                &:hover {
                    color: $clr_primary;
                    text-decoration: underline;
                }
            }
        }
    }

    &__header {
        background: $clr_blue;
        padding: 20px 10px;
        box-sizing: border-box;

        .header-logo {
            width: 100%;

            &__link {
                display: block;
                width: 100%;

                img {
                    margin-left: 18px;
                    width: 200px;
                }
            }
        }
    }

    .catalog__btn {
        width: 100%;
        padding: 15px 1.5rem;
        box-sizing: border-box;
        text-align: center;
        border-bottom: 1px solid #e9e9e9;

        .btn {
            padding: 10px;
            font-size: 16px;
            width: 100%;
        }
    }

    &.open {
        opacity: 1;
        visibility: visible;

        .nav-mobile__wrap {
            transform: translateX(0);
        }

        .nav-mobile__overlay {
            opacity: 1;
            visibility: visible;
        }
    }
}

.profile-menu {
    &__wrap {
        display: flex;
        align-items: center;
    }

    &__item {
        margin-left: 20px;
        position: relative;

        button {
            background: none;
            border: none;
            outline: none;
            padding: 0;

            &:hover,
            &:focus,
            &:active {
                background: transparent !important;
                outline: none !important;
                border: none !important;
                box-shadow: none !important;
            }

            .cart-count {
                position: absolute;
                right: 5px;
                font-size: 10px;
                letter-spacing: .5px;
                background: $clr_primary;
                font-size: 12px;
                font-weight: 500;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                line-height: 19px;
            }

            i {
                font-size: 30px;
                transition: .3s;
                opacity: .5;
            }
        }

        .cart_blocked {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        &>div:hover {
            button {
                background: transparent;

                i {
                    opacity: 1;
                }
            }

            @media screen and (min-width: 992px) {
                .popup-cart {
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .avatar {
            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 30px;
            }
        }
    }

    @media screen and(max-width: 990px) {
        .profile-menu__item {
            margin-left: 0px;
            position: relative;
            transform: translateX(5px);
        }

        .h-profile {
            //display: none;
            margin-left: 5px;
        }
    }
}